import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { MetadataSection } from '../../types/models';

@Component({
  selector: 'ptg-member-navigation-detail',
  templateUrl: './member-navigation-detail.component.html',
  styleUrls: ['./member-navigation-detail.component.scss'],
})
export class MemberNavigationDetailComponent implements OnChanges {
  memberNavigationItem: any;
  listValue: any[] = [];
  value: string = '';

  @Input() currentRow!: any;
  @Input() metadataSection!: MetadataSection;
  @Input() menuItemName!: string;
  @Input() isHidden: boolean = false;
  @Input() memberId!: string;

  @Output() editButtonEvent = new EventEmitter();
  @Output() removeButtonEvent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    this.listValue = [];
    if (changes.currentRow?.currentValue) {
      this.metadataSection?.properties.forEach((p) => {
        this.listValue.push({
          key: p.key,
          value: this.currentRow[p.key],
          title: p.name,
          type: p.type,
          config: p.config,
          options: p.options,
        });
      });
    } else {
      this.metadataSection?.properties.forEach((prop) => {
        this.listValue.push({
          key: prop.key,
          title: prop.name,
          type: prop.type,
          config: prop.config,
          options: prop.options,
        });
      });
    }
  }

  edit() {
    this.editButtonEvent.emit();
  }

  remove() {
    this.removeButtonEvent.emit();
  }
}
