<div class="default-section-container">
  <ptg-overview-header></ptg-overview-header>

  <div
    class="default-section-wrapper flex flex-col p-6 gap-6"
    id="scrollTopBanner"
  >
    <ng-container
      *ngIf="
        ((isList === true && metadataSection) ||
          (isList === false && metadataNonListSection)) &&
          currentSectionLayout !== undefined &&
          dataTable !== undefined;
        else loadingTemplate
      "
    >
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
        [isHideBannerIcon]="isHideBannerIcon"
        [isHideCloseButton]="isHideCloseButton"
      ></ptg-banner>

      <ng-container *ngIf="isList === true">
        <ptg-group-aggregation
          *ngIf="listArregations?.length"
          [aggregationDetails]="listArregations"
        ></ptg-group-aggregation>

        <div
          *ngIf="
            dataTable?.length &&
            columns !== undefined &&
            ((!metadataSection?.hasAttachment && columns.length > 0) ||
              (metadataSection?.hasAttachment && columns.length > 1))
          "
          class="detail-content"
        >
          <div
            class="selected-record"
            [ngClass]="{ 'has-attachment': metadataSection!.hasAttachment }"
          >
            <ptg-member-navigation-detail
              [currentRow]="dataTable[currentRowIndex]"
              [metadataSection]="metadataSection!"
              [menuItemName]="menuItemName"
              [memberId]="memberId"
              [isHidden]="menuItemName =='Contributions'"
              (editButtonEvent)="editMetaDataForm()"
              (removeButtonEvent)="removeMetaData()"
            ></ptg-member-navigation-detail>
          </div>

          <div class="member-attachment" *ngIf="metadataSection?.hasAttachment">
            <ptg-manage-member-attachment
              [menuId]="menuId"
              [memberId]="memberId"
              [rowId]="dataTable[currentRowIndex]?.rowId"
              [dataTable]="dataAttachment"
              [isLoading]="isLoadingAttachments"
              [metadataSection]="metadataSection"
              [lengthPg]="attLengthPg"
              [pageSize]="attPageSize"
              [pageNumber]="attPageNumber"
              (pageChange)="onChangePageAttachments($event)"
            ></ptg-manage-member-attachment>
          </div>
        </div>

        <ptg-member-navigation-list
          [isLoading]="isLoadingDataTable"
          [isHidden]="menuItemName =='Contributions'"
          [dataTable]="dataTable"
          [columns]="columns"
          [sortInfo]="sortInfo"
          [lengthPg]="lengthPg"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          [currentRowIndex]="currentRowIndex"
          [menuItemName]="menuItemName"
          [errorMsg]="errorMsg"
          [sectionHasAttachment]="metadataSection?.hasAttachment"
          (changePaggingEvent)="changePagging($event)"
          (addNewMetaDataEvent)="openMetaDataForm($event)"
          (sortChangeEvent)="sortChange($event)"
          (selectRowEvent)="selectRow($event)"
          (dropStatementEvent)="openDropPopup()"
        ></ptg-member-navigation-list>
      </ng-container>

      <ng-container *ngIf="isList === false">
        <ptg-member-navigation-detail-nonlist
          [currentRow]="dataTable[currentRowIndex]"
          [metadataNonListSection]="metadataNonListSection!"
          [metadataSection]="metadataSection"
          [menuItemName]="menuItemName"
          [memberId]="memberId"
          [currentSectionLayout]="currentSectionLayout"
          (editButtonEvent)="editMetaDataForm()"
        ></ptg-member-navigation-detail-nonlist>

        <ptg-member-navigation-attachment-list
          *ngIf="metadataSection?.hasAttachment"
          [errorMsg]="errorMsg"
          [isLoading]="isLoadingDataTable"
          [memberAttachmentListsData]="attachments"
          [sortInfo]="sortInfo"
          [lengthPg]="attLengthPg"
          [pageSize]="attPageSize"
          [pageNumber]="attPageNumber"
          [currentRowIndex]="currentRowIndex"
          (changePaggingEvent)="changeAttachmentPagging($event)"
          (upLoadFileEvent)="onOpenUpLoadAttachment($event)"
          (removeMetaDataAttachmentEvent)="onRemoveAttachment($event)"
          (editMetadataAttachmentEvent)="onOpenUpLoadAttachment($event)"
          (openPDFEvent)="onOpenPDF($event)"
          (sortChangeEvent)="sortChange($event)"
        ></ptg-member-navigation-attachment-list>
      </ng-container>
    </ng-container>

    <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="true" style="margin: auto"></ptg-spinner>
    </ng-template>
  </div>
</div>
