import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageEvent } from '@ptg-shared/controls/pagination';
import { Column } from '@ptg-shared/controls/grid';

@Component({
  selector: 'ptg-member-navigation-list',
  templateUrl: './member-navigation-list.component.html',
  styleUrls: ['./member-navigation-list.component.scss'],
})
export class MemberNavigationListComponent {
  @Input() dataTable: any = [];
  @Input() columns?: Column[];
  @Input() sortInfo: any = {};
  @Input() lengthPg!: number | any;
  @Input() pageSize: number = 7;
  @Input() pageNumber: number = 1;
  @Input() isLoading!: boolean;
  @Input() currentRowIndex: number = 0;
  @Input() menuItemName!: string;
  @Input() errorMsg!: string;
  @Input() sectionHasAttachment: boolean | undefined;
  @Input() isHidden: boolean = false;

  @Output() selectRowEvent = new EventEmitter();
  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter<PageEvent>();
  @Output() addNewMetaDataEvent = new EventEmitter();
  @Output() dropStatementEvent = new EventEmitter();

  selectRow(event: any) {
    this.selectRowEvent.emit(event);
  }

  sortChange(event: any) {
    this.sortChangeEvent.emit(event);
  }

  changePagging(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }
  
  addNew(event: any) {
    this.addNewMetaDataEvent.emit(event);
  }

  dropStatement(event: any) {
    this.dropStatementEvent.emit(event);
  }
}
