import '../app/shared/auth/extensions/auth0.extension';

export const environment = {
  production: false,
  devTeam: window['ngEnv'].dev.team,
  apiUrl: `https://${window['ngEnv'].dev.team}-ptg-penproplus-api-${window['ngEnv'].dev.env}.${window['ngEnv'].dev.apexdomain}`,
  blobUrl: `https://st${window['ngEnv'].dev.team}edge${window['ngEnv'].dev.env}${window['ngEnv'].dev.location}.blob.core.windows.net/media/`,
  reportUrl: `https://${window['ngEnv'].dev.team}-ptg-penproplus-api-${window['ngEnv'].dev.env}.ptgppplus.org`,
  memberAppUrl: `https://${window['ngEnv'].dev.team}-ptg-penapp-${window['ngEnv'].dev.env}.${window['ngEnv'].dev.apexdomain}/f`,
  appInsights: {
    instrumentationKey: window['ngEnv'].appInsightsKey,
  },
  auth0Config: {
    config: {
      apiUrl: `https://${window['ngEnv'].dev.team}-ptg-penproplus-api-${window['ngEnv'].dev.env}.${window['ngEnv'].dev.apexdomain}`,
      domain: 'auth.ptgppplus.org',
      clientId: 'In3sPNhAb2iyEUcFLXFqvq59HYzLzdsD',
      audience: 'https://ptgportaldevuat.onmicrosoft.com/ptg-admin',
      redirectUri: window.location.origin,
      errorPath: '/error',
      httpInterceptor: {
        allowedList: [`*`],
      },
      // appKey: 'PensionAdmin',
      appKey: window['ngEnv'].dev.appKey,
      databaseConnection: 'Username-Password-Authentication',
      devTeam: window['ngEnv'].dev.team,
    },
    claimNamespace: 'https://ptg.pension.com/',
  },
  defaultSenderMail: 'testlocalnew@mailinator.com',
};

declare global {
  interface Window {
    ngEnv: any;
  }
}
